<template>
  <footer class="m-1 border-2 border-[#203e29] bg-black/10 pt-4">
    <div class="text-center my-2">
      <a target="_blank" href="https://twitter.com/Ariadne_finance" class="mx-1 btn btn-link text-primary-300">
        <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.22108 16.5833C14.8865 16.5833 19.0785 10.332 19.0785 4.9112C19.0785 4.73367 19.0748 4.55695 19.0667 4.38096C19.8824 3.79999 20.5863 3.08066 21.1455 2.25668C20.3988 2.58347 19.5952 2.80341 18.7523 2.90258C19.6127 2.39473 20.2732 1.59129 20.5849 0.633593C19.7668 1.1113 18.8718 1.44806 17.9386 1.62935C17.1781 0.831964 16.0954 0.333252 14.8966 0.333252C12.5951 0.333252 10.7289 2.1704 10.7289 4.43495C10.7289 4.75691 10.7655 5.06996 10.837 5.37035C7.37334 5.19879 4.30203 3.56639 2.24654 1.08425C1.87633 1.71029 1.68161 2.42181 1.68225 3.14613C1.68225 4.56938 2.41796 5.82579 3.5368 6.56086C2.87499 6.54096 2.22771 6.365 1.64939 6.04777C1.64877 6.06499 1.64877 6.08177 1.64877 6.10017C1.64877 8.08686 3.08526 9.74562 4.99216 10.1215C4.63402 10.2175 4.26445 10.266 3.89326 10.2658C3.62519 10.2658 3.36394 10.2399 3.11003 10.1919C3.64059 11.8218 5.17906 13.008 7.0031 13.0411C5.57672 14.1416 3.77993 14.7971 1.82708 14.7971C1.49488 14.7974 1.16294 14.7784 0.833008 14.7403C2.6774 15.904 4.86743 16.583 7.22129 16.583" fill="currentColor" />
        </svg>
      </a>

      <a target="_blank" href="https://t.me/ariadne_finance" class="mx-1 btn btn-link text-primary-300">
        <svg width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" /></svg>
      </a>

      <a target="_blank" href="https://medium.com/ariadne-project" class="mx-1 btn btn-link text-primary-300">
        <svg width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M0 32v448h448V32H0zm372.2 106.1l-24 23c-2.1 1.6-3.1 4.2-2.7 6.7v169.3c-.4 2.6.6 5.2 2.7 6.7l23.5 23v5.1h-118V367l24.3-23.6c2.4-2.4 2.4-3.1 2.4-6.7V199.8l-67.6 171.6h-9.1L125 199.8v115c-.7 4.8 1 9.7 4.4 13.2l31.6 38.3v5.1H71.2v-5.1l31.6-38.3c3.4-3.5 4.9-8.4 4.1-13.2v-133c.4-3.7-1-7.3-3.8-9.8L75 138.1V133h87.3l67.4 148L289 133.1h83.2v5z" /></svg>
      </a>

      <a target="_blank" href="mailto:hello@ariadne.finance" class="mx-1 btn btn-link text-primary-300">
        <svg width="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z" /></svg>
      </a>
    </div>

    <div v-if="false" class="text-center my-2">
      <a class="mx-3 text-primary-300 hover:text-primary-200 transition-colors duration-300" target="_blank" href="https://ariadne.finance/">ABOUT</a>
      <a class="mx-3 text-primary-300 hover:text-primary-200 transition-colors duration-300" target="_blank" href="https://ariadne.helpsite.com">FAQ &amp; SUPPORT</a>
      <a class="mx-3 text-primary-300 hover:text-primary-200 transition-colors duration-300" target="_blank" href="https://ariadne-finance.gitbook.io">DOCS</a>
    </div>

    <div class="copyright">
      <div class="grow" />
      <div class="shrink-0 inverted mx-2 text-lg">
        © 2021-2025 ARIADNE
        <span class="mx-1 mt-[3px] opacity-70">🇺🇦</span>
      </div>
      <div class="grow" />
    </div>
  </footer>
</template>
